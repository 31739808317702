.unlocked-page {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scrollbar-width: none; // for Firefox

  &::-webkit-scrollbar {
    // for other browsers
    display: none;
  }

  background-color: #f2b8b4;
  width: 100vw;
  height: 100vh;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../img/hearts.png");
  background-size: 40%;

  .pics {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // padding: 20px;
    height: 90%;
    width: 100%;

    .gallery-item {
      padding: 0.5rem;
      font-size: 1.2rem;
      text-align: center;
      background-color: #333;
      color: #d9d9d9;
      scroll-snap-align: center;
      flex-grow: 1;
      margin: auto;
    }

    .gallery-img {
      max-width: 100%;
      max-height: 90%;
    }
  }

  .letter {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    max-width: 40vw;
    height: 90vh;
    margin: 0 auto;
    position: relative;
    overflow: auto;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; // for Firefox

    &::-webkit-scrollbar {
      // for other browsers
      display: none;
    }

    .date {
      font-size: 18px;
      padding-bottom: 10px;
      padding-top: 10px;
      color: black;
    }
    .header {
      text-align: center;
      margin-bottom: 20px;
      color: #ff3d2f;
      font-family: "Satisfy", cursive;
    }
    .content {
      // line-height: 1.5;
      text-align: justify;
    }
    .signature {
      text-align: right;
      font-style: italic;
      margin-top: 20px;
      color: #ff3d2f;
      font-family: "Satisfy", cursive;
    }
  }
}
