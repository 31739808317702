@import "../../global.scss";

li {
    color: var(--textColor);
    margin-top: 1.25rem;
    padding: 0.5rem;
    border-radius: 1rem;
    cursor: pointer;

    &.active {
        background-color: var(--secondaryColor);
        color: white;
    }

    @media (max-width: 900px) {
        font-size: 0.9rem;
    }

    @media (min-width: 901px) {
        font-size: 1.5rem;
    }
}