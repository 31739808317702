@import "../../global.scss";

$color: #{var(--primaryColor)};

.projects {
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 400;
    color: var(--textColor);

  }

  ul {
    width: inherit;
    margin: 0.5rem;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-around;

  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    padding-top: 2rem;
  }

  .wrapper {
    .button {
      display: inline-block;
      padding: .75rem 1.25rem;
      border-radius: 10rem;
      text-decoration: none;
      color: #fff;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: .15rem;
      transition: all .3s;
      position: relative;
      overflow: hidden;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--secondaryColor);
        border-radius: 10rem;
        z-index: -2;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: var(--primaryDarken);
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
      }

      &:hover {
        color: #fff;

        &:before {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 900px) {
    width: 100vw;
    // text-align: center;

    h1 {
      font-size: 2.25rem;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;

    }

  }

  @media (min-width: 901px) {
    width: calc(100vw - 11rem);
    height: 100vh;

    h1 {
      font-size: 2.75rem;

    }
  }
}