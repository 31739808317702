.password-page {
  background-color: var(--backgroundColor);
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .locked-page {
    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        margin-bottom: 1rem;
        padding: 0.5rem;
      }

      button {
        padding: 0.5rem 1rem;
      }
    }
  }
}
