// :root {
//     --primaryColor: #1DB954;
//     // Use https://pinetools.com/darken-color with primary color (20% darken) for primaryDarken
//     --primaryDarken: #179343; 
//     --secondaryColor: #1DB954;
//     --tertiaryColor: #222629; 
//     --backgroundColor: #222629;
//     --textColor: white;
//     --togglebg: rgba(0,0,0,0.7);
//     --filterHover: invert(48%) sepia(97%) saturate(402%) hue-rotate(88deg) brightness(98%) contrast(89%);
//     --filter: invert(100%) sepia(1%) saturate(3808%) hue-rotate(322deg) brightness(108%) contrast(101%);
// }

:root {
    --primaryColor: #0ef5d3;
    // Use https://pinetools.com/darken-color for darken
    --primaryDarken: #40485b; 
    --secondaryColor: #5c6783;
    --tertiaryColor: #191919; 
    --backgroundColor: #191919;
    --textColor: white;
    --togglebg: rgba(0,0,0,0.7);
    --filterHover: invert(73%) sepia(80%) saturate(482%) hue-rotate(109deg) brightness(97%) contrast(98%);
    --filter: invert(100%) sepia(1%) saturate(3808%) hue-rotate(322deg) brightness(108%) contrast(101%);
}

[data-theme='dark'] {
    --primaryColor: #3aafa9;
    // Use https://pinetools.com/darken-color for darken
    --primaryDarken: #395b68;
    --secondaryColor: #528395;
    --tertiaryColor: #cde6e4; 
    --backgroundColor: #cde6e4;
    --textColor: black;
    --filterHover: invert(64%) sepia(32%) saturate(742%) hue-rotate(128deg) brightness(87%) contrast(90%);
    --filter: invert(0%) sepia(0%) saturate(7466%) hue-rotate(315deg) brightness(99%) contrast(107%);
}