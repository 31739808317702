@import "../../global.scss";

.experienceBox{
    display: flex;
    justify-content: space-around;
    width: 80%;
    align-items: center;
    border: 3px solid var(--primaryColor);
    border-radius: 1em;
    // margin: 0rem 2rem 2rem 2rem;
    transition: transform 0.45s ease, box-shadow 0.45s ease ;
    text-decoration: none;
    color:var(--textColor);
    background-color: var(--tertiaryColor);

    @media (max-width: 900px) {
        flex-direction: column;
        padding: 2rem;
    }

    @media (min-width: 901px) {
        padding: 2rem;

    }

    .info{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 2rem;

        @media (max-width: 900px) {
            width: 97%;
            align-items: center;
            
            .tags{
                display: wrap;
            }
    
            .tags h5{
                margin: 0.7rem 0.7rem 0.7rem 0;
                font-size: 0.75em;

            }

            .e-link {
                margin-bottom: 1.25rem;
            }
        }
    
        @media (min-width: 901px) {
            width: 70%;
            align-items: flex-start;
            margin-left: 2rem;

            .tags{
                display: flex;
                // width: inherit;
            }
    
            .tags h5{
                margin: 0.7rem 1.3rem 0.7rem 0;
                font-size: 0.75em;
            }
    
        }

        .tags{
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }

        .tags h5{
            color: black;
            padding: 0.5em 1.2em 0.5em 1.2em;
            border-radius: 2em;
            font-weight: 500;
            text-align: center;

        }
        
        .title{
            font-weight: 500;
            letter-spacing: 2px;
            font-size: 1.3em;
            color: var(--primaryColor);
        }

        .role{
            width: 95%;
            font-size: 1.7em;
            font-weight: 600;
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;
        }

        .dates{
            font-weight: 500;
            letter-spacing: 2px;
            font-size: 1em;
        }



        .description{
            font-weight: 300;
            font-size: 0.95em;
        }

        .e-link{
            text-decoration: none;
            color: var(--textColor);
            opacity: 0.9;
            margin-top: 0.9rem;
            font-size: 1.08em;
            font-weight: 600;
            transition: color 0.5s ease, transform 0.5s ease;
        }

        .e-link:hover{
            transform: translateX(0.6em);
        }
    }

    .image{
        display: flex;
        align-items: center;
    }
}

.experienceBox:hover{
    box-shadow: 14px 14px 14px -8px var(--secondaryColor);  
    // transform: translateY(-10px);
}

.experienceBox img{
    border-radius: 0.9em;

    @media (max-width: 900px) {
        width: 15em;
    }

    @media (min-width: 901px) {
        width: 17em;
    }
}

