@import "../../global.scss";

.contact {
  background-color: var(--backgroundColor);
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: var(--textColor);
    font-weight: 400;
    font-size: 2rem;
    text-align: center;
    padding: 0.5rem;
    margin-top: 2rem;


  }

  p {
    color: var(--textColor);
    font-weight: 200;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 2rem;
    padding: 1rem;


  }

  h3 {
    color: var(--textColor);
    font-weight: 200;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 1rem;
    padding: 1rem;

    a {
      text-decoration: underline;
      text-underline-offset: 8px;
      color: var(--primaryColor);
      
    }
  }

  .social-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 2rem;

    &__button {
      margin: 0px 1rem 0;
    }
  }

  $social-button-size: 4rem;
  $social-button-icon-size: 0.4;
  $social-button-background: rgb(198, 197, 212);
  $social-button-active-color: #fff;
  $social-button-transition-time: 0.3s;

  $social-button-colors: ('envelope': #e4405f,
    'linkedin': #0077b5,
    'github': #6e5494,
  );

  .social-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: $social-button-size;
    height: $social-button-size;
    text-decoration: none;
    border-radius: 100%;
    background: $social-button-background;
    text-align: center;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      border-radius: 100%;
      transition: $social-button-transition-time;
    }

    &:focus,
    &:hover {
      color: $social-button-active-color;

      &::after {
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        margin-left: calc(-50% - 1px);
      }
    }

    i,
    svg {
      position: relative;
      z-index: 1;
      transition: $social-button-transition-time;
    }

    i {
      font-size: $social-button-size * $social-button-icon-size;
    }

    svg {
      height: percentage($social-button-icon-size);
      width: percentage($social-button-icon-size);
    }

    @each $name,
    $color in $social-button-colors {
      &--#{$name} {
        color: $color;

        &::after {
          background: $color;
        }
      }
    }

    .wrapper {
    .button {
      display: inline-block;
      padding: .75rem 1.25rem;
      border-radius: 10rem;
      text-decoration: none;
      color: #fff;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: .15rem;
      transition: all .3s;
      position: relative;
      overflow: hidden;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--primaryColor);
        border-radius: 10rem;
        z-index: -2;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: var(--primaryDarken);
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
      }

      &:hover {
        color: #fff;

        &:before {
          width: 100%;
        }
      }
    }
  }
  }


  @media (max-width: 900px) {
    width: 100vw;

  }

  @media (min-width: 901px) {
    width: calc(100vw - 11rem);
    height: 100vh;
  }
}