@import "./global.scss";

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: var(--secondaryColor);
}

::selection {
  color: white;
  background: var(--secondaryColor);
}

.app {
  height: 100vh;

  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scrollbar-width: none; // for Firefox
  font-family: "Quicksand", sans-serif;

  &::-webkit-scrollbar {
    // for other browsers
    display: none;
  }

  @media (max-width: 900px) {
    .sections {
      width: 100vw;
      top: 6rem;

      > * {
        width: 100vw;
      }
    }
  }

  @media (min-width: 901px) {
    .sections {
      width: calc(100vw - 11rem);
      height: 100vh;
      left: 11rem;

      .ignore {
        width: calc(100vw - 11rem);
        height: 100vh;
      }
    }
  }

  .sections {
    background-color: var(--backgroundColor);
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; // for Firefox

    &::-webkit-scrollbar {
      // for other browsers
      display: none;
    }

    > * {
      scroll-snap-align: start;
    }
  }
}
