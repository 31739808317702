@import "../../global.scss";

.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--backgroundColor);

    .top {
        flex: 0.95;
        margin: 1rem;

        .wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--textColor);
            gap: 1.5rem;
            text-align: center;

            h1 {
                font-weight: 500;
            }

            h2 {
                font-weight: 400;
                color: var(--backgroundColor);

                span {
                    font-size: inherit;
                    color: var(--primaryColor);
                }

                .ityped-cursor {
                    font-size: 2.2rem;
                    color: var(--textColor);
                    opacity: 1;
                    -webkit-animation: blink 0.3s infinite;
                    -moz-animation: blink 0.3s infinite;
                    animation: blink 0.3s infinite;
                    animation-direction: alternate;
                }

                @keyframes blink {
                    50% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }

                @-webkit-keyframes blink {
                    50% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }

                @-moz-keyframes blink {
                    50% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }
            }

            h3 {
                font-weight: 200;
                color: var(--textColor)
            }

            h4 {
                font-weight: 200;
            }

            a {
                color: inherit;
                text-decoration: none;
            }

        }

    }

    .downarrow {
        animation: dropeffect 0.8s ease;
        -moz-animation: bounce 2s infinite;
        -webkit-animation: bounce 2s infinite;
        animation: bounce 2s infinite;

        i {
            font-size: 3rem;
            color: var(--textColor);
        }

    }

    @media (max-width: 900px) {
        height: calc(100vh - 6rem);
        width: 100vw;

        h1 {
            font-size: 4.75rem;
        }

        h2 {
            font-size: 1.5rem;
        }

        h3 {
            font-size: 1.25rem;
        }

        h4 {
            font-size: 1rem;
        }
    }

    @media (min-width: 901px) {
        width: calc(100vw - 11rem);
        height: 100vh;

        h1 {
            font-size: 6rem;
        }

        h2 {
            font-size: 2rem;
        }

        h3 {
            font-size: 1.75rem;
        }

        h4 {
            font-size: 1.25rem;
        }
    }
}

@keyframes dropeffect {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(10px);
    }

    60% {
        transform: translateY(10px);
    }
}