@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "../../global.scss";

.slick-prev {
    left: 2% !important;
    z-index: 3;
}

.slick-next {
    right: 2% !important;
    z-index: 3;
}

.experience {
    background-color: var(--backgroundColor);
    display: flex;
    flex-direction: column;

    // align-items: center;
    justify-content: center;

    h1 {
        font-weight: 400;
        color: var(--textColor);
        text-align: center;

    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .container {
            display: flex;
            justify-content: center;
            margin-top: 2rem;
            padding-bottom: 1rem;

            @media (max-width: 900px) {
                margin-left: 2.5rem;
                margin-right: 2.5rem;
            }
        }
    }




    @media (max-width: 900px) {
        width: 100vw;
        // text-align: center;
        padding-bottom: 4rem;
        padding-top: 3rem;

        h1 {
            font-size: 2.25rem;

        }

    }

    @media (min-width: 901px) {
        width: calc(100vw - 11rem);
        height: 100vh;

        h1 {
            font-size: 2.75rem;

        }
    }
}