@import "../../global.scss";

$hover: var(--primaryColor);

.toggle {

    *, *:before, *:after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    :root {
        /* minFontSize + (maxFontSize - minFontSize) * (100vw - minVWidth)/(maxVWidth - minVWidth) */
        font-size: calc(64px + (80 - 64) * (100vw - 320px)/(960 - 320));
    }
    body, input {
        font-size: 1em;
        line-height: 1.5;
    }
    body {
        background: #bbb;
    }
    input {
        display: block;
        margin-bottom: 1.5em;
    }
    main {
        padding: 1.5em 0 0 0;
        text-align: center;	
    }
    .l {
        background-color: rgba(0,0,0,0.7);
        border-radius: 0.75em;
        box-shadow: 0.125em 0.125em 0 0.125em rgba(0,0,0,0.3) inset;
        color: #fdea7b;
        display: inline-flex;
        align-items: center;
        margin: auto;
        padding: 0.15em;
        width: 3em;
        height: 1.5em;
        transition: background-color 0.1s 0.3s ease-out, box-shadow 0.1s 0.3s ease-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
    }
    .l:before, .l:after {
        content: "";
        display: block;
    }
    .l:before {
        background-color: #d7d7d7;
        border-radius: 50%;
        width: 1.2em;
        height: 1.2em;
        transition: background-color 0.1s 0.3s ease-out, transform 0.3s ease-out;
        z-index: 1;
    }
    .l:after {
        background:
            linear-gradient(transparent 50%, rgba(0,0,0,0.15) 0) 0 50% / 50% 100%,
            repeating-linear-gradient(90deg,#bbb 0,#bbb,#bbb 20%,#999 20%,#999 40%) 0 50% / 50% 100%,
            radial-gradient(circle at 50% 50%,#888 25%, transparent 26%);
        background-repeat: no-repeat;
        border: 0.25em solid transparent;
        border-left: 0.4em solid #d8d8d8;
        border-right: 0 solid transparent;
        transition: border-left-color 0.1s 0.3s ease-out, transform 0.3s ease-out;
        transform: translateX(-22.5%);
        transform-origin: 25% 50%;
        width: 1.2em;
        height: 1em;
    }
    /* Checked */
    .l:checked {
        background-color: rgba(0,0,0,0.45);
        box-shadow: 0.125em 0.125em 0 0.125em rgba(0,0,0,0.1) inset;
    }
    .l:checked:before {
        background-color: currentColor;
        transform: translateX(125%)
    }
    .l:checked:after {
        border-left-color: currentColor;
        transform: translateX(-2.5%) rotateY(180deg);
    }
    /* Other States */
    .l:focus {
        /* Usually an anti-A11Y practice but set to remove an annoyance just for this demo */
        outline: 0;
    }

    
}

.topbar .top img:hover{
    filter: var(--filterHover)
}

.topbar .top img {
    filter: var(--filter)
}


@media (max-width: 900px) {
    .topbar {
        width: 100%;
        height: 6.5rem;
        background-color: var(--tertiaryColor);
        color: var(--primaryColor);
        position: fixed;
        top: 0;
        z-index: 2;
        overflow: hidden;
        border-bottom: solid 0.5rem var(--secondaryColor);

        .wrapper {
            padding: 0.75rem 0.75rem;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .top {
                margin-top: 0.75rem;
            }

            .toggle {
                margin-left: 1em;
            }

            .middle {
                width: 60vw;
                display: flex;
                align-items: center;
                justify-content: space-around;

                .logo {
                    font-size: 1.15rem;
                    font-weight: 600;
                    text-decoration: none;
                    color: inherit
                }

                .resume:hover, .resume:focus {
                    box-shadow: inset 0 0 0 2em $hover;
                }

                .resume {  
                    color: var(--primaryColor);
                    transition: 0.25s;
                    font-size: 1.15rem;
                    font-weight: 600;
                    text-decoration: none;
                    background: none;
                    border: 2px solid var(--primaryColor);
                    line-height: 1;
                    padding: 0.5em 0.5em;
                    border-radius: 1rem;
                    
                    &:hover,
                    &:focus { 
                      border-color: $hover;
                      color: white;
                    }
                }


                .exp, .proj{
                    display: none;
                }

            }
        }
    }
}

@media (min-width: 901px) {
    .topbar {
        width: 11rem;
        height: 100vh;
        background-color: var(--tertiaryColor);
        color: var(--primaryColor);
        position: fixed;
        top: 0;
        z-index: 2;
        overflow: hidden;
        border-right: solid 0.5rem var(--secondaryColor);

        .wrapper {
            padding: 3rem 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .top {
                margin-bottom: 10vh;
            }

            .middle {
                height: 65vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;

                .logo {
                    font-size: 20px;
                    font-weight: 400;
                    text-decoration: none;
                    color: var(--textColor);
                    transition: 0.25s;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    text-align: center;

                    &:hover,
                    &:focus { 
                      border-color: $hover;
                      color: var(--primaryColor);
                    }

                    &.active {
                        color: var(--primaryColor);
                    }
                }

                .resume:hover, .resume:focus {
                    box-shadow: inset 0 0 0 2em $hover;
                }

                .resume {  
                    color: var(--primaryColor);
                    transition: 0.25s;
                    font-size: 20px;
                    font-weight: 400;
                    text-decoration: none;
                    background: none;
                    border: 2px solid var(--primaryColor);
                    line-height: 1;
                    padding: 0.65em 0.65em;
                    border-radius: 1rem;
                    
                    &:hover,
                    &:focus { 
                      border-color: $hover;
                      color: white;
                    }
                }

            }
        }
    }
}